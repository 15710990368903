import DOMPurify from "isomorphic-dompurify"
import HTMLDecoderEncoder from "html-encoder-decoder"

const sanitize = (text: any = '', options = {}) => {

  try {

    if (typeof text === 'string') {

      // Decode HTML entities in the input string to their original characters
      const encodedText = HTMLDecoderEncoder.decode(text)

      // Sanitize the decoded text to remove any potentially harmful content
      const sanitizedText = DOMPurify.sanitize(encodedText, options)

      // Decode HTML entities again after sanitization to ensure the final output is clean
      return HTMLDecoderEncoder.decode(sanitizedText)

    }

    // If the input is not a string, return it as-is
    return text

  } catch (e) {

    // Return an empty string in case of any errors during sanitization
    return ''

  }

}

export default sanitize