import React, { FC, useState } from 'react';
import styles from './index.module.scss';

// 3rd party libraries
import classnames from 'classnames';
import {
  Container,
  Row,
  Col,
  CardDeck,
  CardGroup,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardFooter,
  Button,
} from 'reactstrap';
import Truncate from 'react-truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { faListRadio } from '@fortawesome/pro-light-svg-icons';

// Components
import CardsImage from '../../Cards/CardsImage/CardsImage';
import CardsCompanyLogo from '../../Cards/CardsCompanyLogo/CardsCompanyLogo';

// Libraries
import sessionsCount from '../../../libs/sessionsCount';
import gridLink from '../../../libs/gridLink/gridLink';
import sanitize from '@bt-react/libs/sanitize';

interface listGrids {
  gridItemName?: string; // What are all the grid item names?@hugo? Lets change this to string literal type
  listStyle?: string; // Can we change this to string literal type as well.
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  clickableCard?: boolean;
  pageType?: string; // needed to pass into the image comp to try and format different resolutions.
  footLinkTitle: string;
  gridData: SummitsArray[];
  flexDirection?:
    | 'flex-row'
    | 'flex-reverse-row'
    | 'flex-reverse-column'
    | 'flex-column';
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  type: 'summits' | 'series';
  utmParams?: string;
  dataBddTitle?: string;
  dataBddDesc?: string;
  summitUrl: string;
  seriesUrl: string;
  priorityStatus?: boolean;
}

export interface SummitsArray {
  id: number;
  title: string;
  description: string;
  scheduledStartDate: Date;
  scheduledEndDate: Date;
  visibility: string;
  imageUrl: string;
  companyLogo: string;
  tracks: Track[];
  featured: boolean;
  wordPressLink: string;
}

export interface Track {
  id: number;
  name: string;
  communications: Communication[];
}

export interface Communication {
  channelId: number;
  communicationId: number;
}

const SeriesGridItems: FC<listGrids> = ({
  gridItemName,
  listStyle,
  listLayout,
  cardLayout,
  clickableCard,
  pageType,
  gridData,
  flexDirection,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  footLinkTitle,
  type,
  utmParams,
  dataBddTitle,
  dataBddDesc,
  summitUrl,
  seriesUrl,
  priorityStatus,
}) => {
  const Data = gridData;

  let siteLink, titleLength, envUrl;

  if (type === 'summits') {
    envUrl = summitUrl;
    siteLink = envUrl + '/summit/';
    titleLength = 1; // text truncation value
  } else {
    envUrl = seriesUrl;
    siteLink = envUrl + '/series/';
    titleLength = 2; // text truncation value
  }

  return (
    <>
      <Row
        className={classnames(
          styles[listLayout],
          styles[gridItemName],
          'g-4'
        )}
      >
        {Data?.map(
          (
            {
              id,
              title,
              description,
              imageUrl,
              companyLogo,
              tracks,
              scheduledStartDate,
              scheduledEndDate,
              wordPressLink,
            },
            index
          ) => (
            <Col
              sm={rowColSm}
              md={rowColMd}
              lg={rowColLg}
              xl={rowColXl}
              className={styles['column']}
              key={`SummitGridItemsCol-` + id}
              data-section-name={gridItemName}
            >
              <CardDeck key={`CardDeck-` + id} className={classnames('h-100')}>
                <Card
                  key={`Card-` + id}
                  className={classnames(
                    styles['list-item'],
                    styles[gridItemName],
                    styles[listStyle],
                    styles[cardLayout],
                    'h-100',
                    styles[`cardnumber-${index}`]
                  )}
                >
                  {clickableCard === true && (
                    <a
                      className={classnames('stretched-link')}
                      href={gridLink(
                        wordPressLink,
                        id,
                        type,
                        utmParams,
                        gridItemName,
                        index + 1,
                        siteLink
                      )}
                      aria-label={'View ' + title}
                    ></a>
                  )}

                  <div key={`img-wrap-` + id} className={styles['img-wrap']}>
                    <a
                      href={gridLink(
                        wordPressLink,
                        id,
                        type,
                        utmParams,
                        gridItemName,
                        index + 1,
                        siteLink
                      )}
                    >
                      <CardsImage
                        key={`CardsImage-` + id}
                        id={id}
                        href={imageUrl}
                        title={title}
                        gridType={type}
                        pageType={pageType}
                        imageType={'full'}
                        priorityStatus={priorityStatus}
                      />
                    </a>
                  </div>

                  <CardBody
                    key={`CardBody-` + id}
                    className={styles['cardbody']}
                  >
                    <div
                      key={`main-body-` + id}
                      className={styles['main-body']}
                    >
                      {/* For series search page we display title at the top  */}
                      {type == 'series' &&
                        gridItemName === 'search-series-item' && (
                          <a
                            href={gridLink(
                              wordPressLink,
                              id,
                              type,
                              utmParams,
                              gridItemName,
                              index + 1,
                              siteLink
                            )}
                          >
                            <CardTitle
                              tag="h2"
                              className={styles['item-title']}
                              key={`item-title-` + id}
                            >
                              <Truncate
                                key={`Truncate` + id}
                                lines={titleLength}
                              >
                                {sanitize(title)}
                              </Truncate>
                            </CardTitle>
                          </a>
                        )}

                      {/* Series search page shows the episodes count below title  with no comp logo. */}
                      {type === 'series' &&
                        gridItemName === 'search-series-item' && (
                          <div
                            key={`body-header-` + id}
                            className={styles['body-header']}
                          >
                            <span
                              key={`episodesCount-` + id}
                              className={styles['series-count']}
                            >
                              <FontAwesomeIcon
                                icon={faListRadio}
                                className={styles['bt-icons']}
                              />{' '}
                              {sessionsCount(tracks)} episodes
                            </span>
                          </div>
                        )}

                      {/* for homepage summit/series we show title below the series episode count */}
                      {type == 'series' &&
                        gridItemName != 'search-series-item' && (
                          <div
                            key={`body-header-` + id}
                            className={styles['body-header']}
                          >
                            <span
                              key={`episodesCount-` + id}
                              className={styles['series-count']}
                            >
                              <FontAwesomeIcon
                                icon={faListRadio}
                                className={styles['bt-icons']}
                              />{' '}
                              {sessionsCount(tracks)} episodes
                            </span>

                            <div
                              key={`companyLogo` + id + index}
                              className={styles['body-comp-logo']}
                            >
                              <CardsCompanyLogo
                                key={`CardsCompanyLogo` + id + index}
                                href={companyLogo}
                                title={'Series Company Logo'}
                                id={id + index}
                                width={70}
                                height={28}
                                objectFit={'contain'}
                                imageType={'logo'}
                                gridType={'series'}
                              />
                            </div>
                          </div>
                        )}

                      {/* for homepage summit/series we show title below the series episode count */}
                      {type == 'series' &&
                        gridItemName != 'search-series-item' && (
                          <a
                            href={gridLink(
                              wordPressLink,
                              id,
                              type,
                              utmParams,
                              gridItemName,
                              index + 1,
                              siteLink
                            )}
                          >
                            <CardTitle
                              tag="h2"
                              className={styles['item-title']}
                              key={`item-title-` + id}
                              data-bdd={dataBddTitle}
                            >
                              <Truncate
                                key={`Truncate` + id}
                                lines={titleLength}
                              >
                                {sanitize(title)}
                              </Truncate>
                            </CardTitle>
                          </a>
                        )}

                      <CardText
                        tag="p"
                        className={classnames(styles['card-description'])}
                        key={`card-description` + id}
                        data-bdd={dataBddDesc}
                      >
                        <Truncate key={`Truncate` + id} lines={2}>
                          {sanitize(description)}
                        </Truncate>
                      </CardText>

                      <CardText
                        key={`item-link` + id + index}
                        tag="p"
                        className={classnames(
                          styles['item-meta'],
                          styles['series']
                        )}
                      >
                        {footLinkTitle != '' && (
                          <a
                            href={gridLink(
                              wordPressLink,
                              id,
                              type,
                              utmParams,
                              gridItemName,
                              index + 1,
                              siteLink
                            )}
                            key={`footLinkTitle-` + id}
                            data-bdd="see-agenda-link-series"
                          >
                            {footLinkTitle}{' '}
                            <FontAwesomeIcon
                              key={`FontAwesomeIcon-` + id}
                              icon={faAngleRight}
                              className={styles['bt-icons']}
                            ></FontAwesomeIcon>
                          </a>
                        )}
                      </CardText>

                      {/*  For series search page we show the company logo in the footer */}
                      {type == 'series' &&
                        gridItemName === 'search-series-item' && (
                          <CardText className={styles['presenter']}>
                            <div className={styles['presented-by']}>
                              Presented by:
                            </div>
                            <div
                              key={`companyLogo` + id + index}
                              className={classnames(
                                styles['series-search-logo'],
                                styles['body-comp-logo'],
                                styles['series-search']
                              )}
                            >
                              <CardsCompanyLogo
                                key={`CardsCompanyLogo` + id + index}
                                href={companyLogo}
                                title={'Series Company Logo'}
                                id={id + index}
                                width={70}
                                height={28}
                                objectFit={'contain'}
                                imageType={'logo'}
                                gridType={'series'}
                              />
                            </div>
                          </CardText>
                        )}
                    </div>
                  </CardBody>
                </Card>
              </CardDeck>
            </Col>
          )
        )}
      </Row>
    </>
  );
};

export default SeriesGridItems;
