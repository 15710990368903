import React, { FC, useEffect } from 'react';
import Image from 'next/image';
import classnames from 'classnames';
import styles from './index.module.scss';
import SearchButton from '../SearchButton/SearchButton';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import GoogleAds from '../../components/GoogleAds/GoogleAds';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import sanitize from '@bt-react/libs/sanitize';

const userAgent =
  typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

interface breadcrumbsProps {
  parentTitle: string;
  parentHref: string;
  childTitle: string;
}

interface BannerProps {
  pageType?: 'homepage-banner' | 'page-banner';
  title: string;
  description: string;
  search?: boolean;
  theme: 'dark' | 'light';
  breadcrumbs?: breadcrumbsProps;
  hostUrl: string;
}

const HeroBanner: FC<BannerProps> = ({
  pageType = 'page-banner',
  title,
  description,
  search = false,
  theme = 'dark',
  breadcrumbs,
  hostUrl,
}) => {
  const pathname = usePathname();
  const colSize = pathname == '/' ? '7' : '5';
  const [bannerSize, setBannerSize] = useState([]);

  useEffect(() => {
    if (window.innerWidth > 1200) {
      setBannerSize([728, 90]);
    } else {
      setBannerSize([300, 50]);
    }
  }, []);
  return (
    <>
      <div
        className={classnames(
          styles[pageType],
          styles['banners'],
          'generic-hero-banner'
        )}
      >
        {pageType === 'homepage-banner' ? (
          <Image
            className={styles['homepage-image']}
            src="/portal/backgrounds/homepage-hero-image-08-02-24.jpg"
            //blurDataURL="/portal/backgrounds/homepage-hero-image-08-02-24.jpg"
            alt="BrightTALK Homepage Banner"
            quality={75}
            fill
            placeholder="empty"
            priority={true}
            sizes="(max-width: 992px) 50vw, 80vw, (max-width: 1400px) 100vw"
          />
        ) : (
          ''
        )}

        <div className={styles['hero-mask']}></div>

        <Container className={styles['bootstrap-container']}>
          {/* Added breadcrumbs section and component if the {breadcrumbs} object has the property and values */}
          {typeof breadcrumbs !== 'undefined' && breadcrumbs !== null && (
            <div className={styles['breadcrumbs']}>
              <Breadcrumbs
                parentTitle={breadcrumbs?.parentTitle}
                parentHref={breadcrumbs?.parentHref}
                childTitle={breadcrumbs?.childTitle}
              />
            </div>
          )}
          <Row>
            <Col xl={colSize} className={styles['media-padding']}>
              <h1 className={styles[theme]}>{sanitize(title)}</h1>
              <div className={styles['banner-container']}>
                <p className={styles[theme]}>{sanitize(description)}</p>
                {search ? <SearchButton baseUrl={hostUrl} /> : ''}
              </div>
            </Col>
            {pathname != '/' && (
              <Col xl={7} className={styles['google-ads-main']}>
                <div className="d-none d-lg-block">
                  <GoogleAds adUnitPath={pathname} adSize={[728, 90]} />
                </div>
                <div className="d-block d-lg-none">
                  <GoogleAds adUnitPath={pathname} adSize={[300, 50]} />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HeroBanner;
