import React, { FC } from 'react';
import styles from './index.module.scss';

import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretRight,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';

// Libraries
import titleToSlug from '../../libs/titleToSlug/titleToSlug';
import sanitize from '@bt-react/libs/sanitize';

export interface TopicsProps {
  title: string;
  type: 'popular' | 'trending';
  data: Topics[];
}

export interface Topics {
  name: string;
  url: string;
  popularRanking: number;
  rankingThisWeek: number;
  rankingPreviousWeek: number;
  rankingChange: number;
}

const Topics: FC<TopicsProps> = ({
  title = 'Most popular',
  type = 'popular',
  data,
}) => {
  /**
   *
   *
   * @param {number} movementParam
   * @returns Classes and number to match the topics movement
   */
  function trendingMovement(movementParam: number, name: string, url: string) {
    let caretMovementClass;
    let caretMovementIcon;
    if (movementParam < 0) {
      // This is negative
      caretMovementIcon = faCaretDown;
      caretMovementClass = 'fa-caret-down';
    } else if (movementParam > 0) {
      // This is positive
      caretMovementIcon = faCaretUp;
      caretMovementClass = 'fa-caret-up';
    } else if (movementParam === 0) {
      // Topic position hasn't moved
      caretMovementIcon = faCaretRight;
      caretMovementClass = 'fa-caret-right';
    }

    if (type === 'popular') {
      return (
        <td className={styles['trending-movement']}>
          <a
            className={styles['topic-link']}
            href={`/topic/${url}`}
            aria-label={name}
          >
            <FontAwesomeIcon
              icon={caretMovementIcon}
              className={styles[caretMovementClass]}
            />
            <span aria-label={name}>{movementParam}</span>
          </a>
        </td>
      );
    }
    return null;
  }
  /**
   *
   *
   * @param {*} number
   * @returns boolean - [true = even] [false = odd]
   */
  function evenOddRow(number) {
    return number % 2 == 0;
  }

  function topicRow() {
    let topicRows;
    if (type === 'popular' && data) {
      topicRows = data.map(
        (
          {
            popularRanking,
            name,
            url,
            rankingThisWeek,
            rankingPreviousWeek,
            rankingChange,
          },
          index
        ) => (
          <tr
            key={`popular-` + popularRanking}
            className={evenOddRow(popularRanking) ? 'even' : 'odd'}
          >
            <td className={styles['current-position']}>
              <a
                data-bdd={`topic-link`}
                className={styles['topic-link']}
                href={`/topic/${url}`}
                aria-label={name + ' ' + popularRanking}
              >
                <span className={'sr-only'}>{sanitize(name)}</span>{' '}
                {popularRanking}
              </a>
            </td>
            <td className="item-title">
              <a
                data-bdd={`topic-link`}
                id={`current-position-` + url}
                className={styles['topic-link']}
                href={`/topic/${url}`}
              >
                {sanitize(name)}
              </a>
            </td>
            {trendingMovement(rankingChange, name, url)}
          </tr>
        )
      );
    } else if (type === 'trending' && data) {
      topicRows = data.map(({ name, url }, index) => (
        <tr key={`trending-` + index}>
          <td className={styles['current-position']}>
            <a
              data-bdd={`topic-link`}
              className={styles['topic-link']}
              href={`/topic/${url}`}
              aria-label={name + ' ' + (index + 1)}
            >
              <span className={'sr-only'}>{sanitize(name)} </span>
              {index + 1}
            </a>
          </td>
          <td className="item-title">
            <a
              data-bdd={`topic-link`}
              id={`trending-position-` + url}
              className={styles['topic-link']}
              href={`/topic/${url}`}
            >
              {sanitize(name)}
            </a>
          </td>
        </tr>
      ));
    }
    return topicRows;
  }

  return (
    <div className={styles['topics-container']} data-bdd="topics-grid">
      <h2 className="topic-title" data-bdd={titleToSlug(title)}>
        {title}{' '}
      </h2>
      <Table hover className={`bt-tables ${styles['topics-tables']}`}>
        <thead>
          <tr className="sr-only">
            <th>Current position</th>
            <th>Topic Title</th>
            {type === 'popular' && <th>Trending position</th>}
          </tr>
        </thead>
        <tbody>{topicRow()}</tbody>
      </Table>
    </div>
  );
};

export default Topics;
