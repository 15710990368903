import React, { FC, useEffect, useState } from 'react'
import Script from 'next/script';

interface GlobalHeaderProps {
  environment: string
  hostUrl?: string
}

const GlobalFooter: FC<GlobalHeaderProps> = ({ hostUrl, environment = 'prod' }) => {

  const [pageLoaded, setPageLoaded] = useState(false)

  useEffect(() => {
    setPageLoaded(true)
  }, [])

  let baseUrl = hostUrl;
  if ( !hostUrl ){
	  baseUrl = typeof window === 'undefined' ? process.env.HOST : ''
  }

  return (
    <div className="global-footer">
      <Script
        src={baseUrl + `/webcomponent/dist/brighttalk-web-components/brighttalk-web-components.esm.js`}
        type="module"
      />
      <style>{`
       .global-footer {
			background-color: rgb(92, 92, 92);
			min-height: 394px;
		}
      `}</style>
      {/* @ts-ignore */}
      {pageLoaded &&<portal-footer env={environment} />}
    </div>
  );
}

export default GlobalFooter
