import { captureException, captureMessage } from '@sentry/node';

const handleErrorResponse = (url, error) => {
  let errorResponse;

  const errorCode = error?.response?.status;
  // Check for the error code from the error object.
  if (errorCode) {
    switch (errorCode) {
      case 400: {
        errorResponse = '400 Bad Request';
        break;
      }
      case 401: {
        errorResponse = '401 Unauthorized';
        break;
      }
      case 403: {
        errorResponse = '403 Forbidden';
        break;
      }
      case 404: {
        errorResponse = '404 Not Found';
        break;
      }
      case 405: {
        errorResponse = '405 Method Not Allowed';
        break;
      }
      case 500: {
        errorResponse = '500 Internal Server Error';
        break;
      }
      default: {
        errorResponse = error;
        break;
      }
    }
  }
  // Pass an Error object to captureException() to get it captured as event.
  captureException(error);
  // Pass an Error message to captureMessage() to capture a bare message.
  captureMessage(
    `Call to ${url} returned ${errorResponse}, status: ${error.response?.statusText}`
  );
};

export default handleErrorResponse;